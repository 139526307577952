// components
import Seo from 'src/components/Seo';
// hooks
import useJobsMetaData from 'src/cms/data/jobs/useJobsMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const jobsMetaData = useJobsMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={jobsMetaData?.title}
      description={jobsMetaData?.description}
      schema={jobsMetaData?.schema}
    />
  );
};

export { default } from './Jobs';
