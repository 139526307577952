import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useJobsIntroData from 'src/cms/data/jobs/useJobsIntroData';
// sections
import JobsList from './sections/JobsList';
import BecomeTeamMember from 'src/pages/career/sections/BecomeTeamMember';
import { PageProps } from 'gatsby';

const Jobs: FC<PageProps> = () => {
  // hooks
  const { title, mobileTitle, description } = useJobsIntroData();

  return (
    <PageLayout withStartProjectForm={false}>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='supermanDrawing'
        withMargin={false}
      />
      <JobsList />
      <BecomeTeamMember />
    </PageLayout>
  );
};

export default Jobs;
